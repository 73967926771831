.footer-container {
  width: 80vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 20px;
    color: white;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 1.5rem;
    overflow: hidden;

    @include media(">=phone", "<lgphone") {
      display: none;
    }

    @include media(">=lgphone", "<tablet") {
      font-size: 14px;
    }

    @include media(">=tablet", "<desktop") {
      font-size: 1.2rem;
    }
  }

  .email-link {
    font-size: 1.5rem;
    color: white;
  }

  .social-icons {
    margin-top: 20px;

    img {
      height: 2rem;
      margin: 10px;
    }
  }

  span {
    margin-top: 10px;
  }

  icon {
    color: red;
  }
}