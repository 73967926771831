.header-wrapper {
  width: 100%;
  height: 100vh;
  background-image: url('../images/bg/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 34px;
    text-align: left;
    color: white;
    margin: 0;
    text-shadow: 2px 2px 10px black;

    @include media(">=phone", "<lgphone") {
      font-size: 20px;
    }

    @include media(">=lgphone", "<tablet") {
      font-size: 28px;
    }

    @include media(">=tablet", "<desktop") {
      font-size: 30px;
    }
  }

  p {
    font-size: 50px !important;
    color: white;
    font-weight: bold;
    margin: 0;
    text-shadow: 2px 2px 10px black;


    @include media(">=phone", "<lgphone") {
      font-size: 24px !important;
    }

    @include media(">=lgphone", "<tablet") {
      font-size: 32px !important;
    }

    @include media(">=tablet", "<desktop") {
      font-size: 50px !important;
    }
  }

  span {
    color: white;
    font-size: 16px;
    text-shadow: 4px 4px 10px black;
    text-align: center;
  }

  .heading-wrapper {
    h1 {
      font-size: 4rem;
      text-align: center;
      line-height: 20px;
      color: white;

      @include media(">=phone", "<lgphone") {
        font-size: 1.5rem;
        line-height: 10px;
      }

      @include media(">=lgphone", "<tablet") {
        font-size: 1.5rem;
        line-height: 10px;
      }

      @include media(">=tablet", "<desktop") {
        font-size: 2.5rem;
      }
    }
  }

  p {
    width: 50%;
    text-align: center;
    overflow: hidden;

    @include media(">=phone", "<lgphone") {
      font-size: 12px;
      width: 100%;
    }

    @include media(">=lgphone", "<tablet") {
      font-size: 12px;
      width: 100%;
    }

    @include media(">=tablet", "<desktop") {
      font-size: 14px;
      width: 100%;
    }
  }
}