@charset "utf-8";
@import "./include-media.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap");


// Components
@import "./navbar.scss";
@import "./header.scss";
@import "./work.scss";
@import "./about.scss";
@import "./skills.scss";
@import "./footer.scss";

* {
  font-family: "Montserrat";
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #0A0F1D;
}

.section {
  width: 100vw;
  height: auto;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.primary-btn {
  margin: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.ant-tabs {
  .ant-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;

    .ant-tabs-tab-btn {
      .tab-item {
        color: white;
        opacity: 0.4;

        h1 {
          margin: 0;
          font-size: 14px;
          font-family: sans-serif;
          font-weight: 500;
        }

        p {
          font-size: 13px;
          margin: 0;
          font-family: serif;
        }
      }
    }

    [aria-selected="true"] {
      .tab-item {
        opacity: 1;
      }
    }


  }

  .ant-tabs-tabpane {
    color: white;
  }
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none !important;

  svg {
    color: white;
  }
}