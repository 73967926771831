.work-wrapper {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #E2E8F0;



  h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 20px;
    color: white;
    margin-bottom: 16px;
    margin-top: 12px;
  }

  .grid {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
    }

    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
    }

    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr 1fr;
    }
  }


}

.work-items {
  padding: 8px;
  max-width: 400px;

  &:hover {
    opacity: 1;
    background-color: rgba(237, 239, 242, 0.1);
    border-radius: 8px;
    cursor: pointer;

    .details {
      h1 {
        opacity: 1;
      }

      p {
        opacity: 1;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;

    .img-container {
      height: 120px !important;
      width: 150px !important;

      img {
        height: 100px !important;
        width: 120px !important;
        margin: 12px;
        border-radius: 8px;
      }

    }

    h1 {
      font-size: 13px;
      color: white;
      opacity: 0.8;
    }

    .platform {
      display: flex;
      margin-top: -6px;
      font-size: 11px;
      color: white;
      opacity: 0.7;

      span {
        text-decoration: underline;
      }
    }

    .role {
      display: flex;
      margin-top: -2px;
      font-size: 11px;
      color: white;
      opacity: 0.7;

      span {
        text-decoration: underline;
      }
    }
  }


}

.ant-modal-header {
  display: none;

  .ant-modal-title {
    color: white;
  }
}

.ant-modal-content {
  background: #0A0F1D;
  opacity: 0.99;

  .ant-modal-close-x {
    color: white;
    font-size: 40px;
  }
}

.image-gallery-image {
  // height: 600px;
}

.image-gallery-thumbnail-image {
  // height: 100px;
  object-fit: contain;
}

.work-details {
  p {
    font-size: 11px;
    color: white;
    opacity: 0.5;
    padding: 4px 6px;
    margin: 0;
  }

  .tech {
    display: flex;
    flex-wrap: wrap;

    .tech-item {
      color: #54f7df;
      font-size: 10px;
      background-color: rgba(84, 247, 223, 0.1);
      margin: 3px;
      padding: 3px 10px;
      white-space: nowrap;
      border-radius: 5px;
      font-weight: bold;
    }
  }
}

.references {
  margin: 6px;

  h1 {
    color: white;
    font-size: 18px;
  }

  p {
    color: red;
    font-weight: bold;
    font-size: 12px;
  }

  a {
    font-size: 12px;
  }
}