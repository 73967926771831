.navbar-wrapper {
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 20px;
  position: fixed;

  @include media(">=phone", "<lgphone") {
    justify-content: center;
  }

  @include media(">=lgphone", "<tablet") {
    justify-content: center;
  }

  .name {
    font-weight: 600;
    text-decoration: none;

    @include media(">=phone", "<lgphone") {
      display: none;
    }

    @include media(">=lgphone", "<tablet") {
      display: none;
    }
  }

  h1 {
    font-size: 18px;
    color: white;
    margin-left: 24px;
  }

  .links-wrapper {
    margin-right: 24px;

    a {
      text-decoration: none;
      padding: 16px;
      font-family: "Open Sans";
      transition: all 0.2s ease-in-out;
      font-size: 18px;
      background-color: transparent;
      outline: none;
      border: 0px;
      cursor: pointer;
      color: white;
      text-shadow: 2px 2px black;
      font-family: serif;

      &:hover {
        opacity: 1;
      }
    }

    .active {
      color: black;
    }
  }
}