.about-section {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  justify-content: space-between;

  @include media(">=phone", "<lgphone") {
    flex-direction: column-reverse;
  }

  @include media(">=lgphone", "<tablet") {
    flex-direction: column-reverse;
  }

  @include media(">=tablet", "<desktop") {
    flex-direction: column-reverse;
    align-items: center;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .me {
      max-width: 700px;
      text-align: justify;
    }

    h1 {
      color: white;
      text-align: center;
      font-size: 3rem;
      font-weight: bold;
      line-height: 20px;
      color: white;
      margin-bottom: 60px;
    }

    p {
      color: white;
    }

    @include media(">=phone", "<lgphone") {
      width: 100%;
    }

    @include media(">=lgphone", "<tablet") {
      width: 100%;
    }

    @include media(">=tablet", "<desktop") {
      width: 100%;
    }

    h1 {
      font-size: 3rem;
      line-height: 20px;

      @include media(">=tablet", "<desktop") {
        text-align: center;
      }
    }

    p {
      @include media(">=tablet", "<desktop") {
        text-align: center;
      }
    }

    .collab {
      margin: 24px 0;

      h1 {
        font-size: 16px;
        margin: 0;
        margin: 24px;
      }

      .content {
        max-width: 700px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2px 8px;

          .img-wrapper {
            // margin: 6px;
            border-radius: 12px;

            img {
              height: 35px;
              width: 30px;
              object-fit: contain;

            }
          }

          h1 {
            margin: 0;
            font-size: 12px;
            margin-left: 6px;
            white-space: nowrap;
            font-family: 'sans-serif';
          }
        }
      }
    }

    .professional {
      max-width: 700px;

      h1 {
        font-size: 16px;
        margin: 24px;
      }

      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  .image-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include media(">=phone", "<lgphone") {
      width: 100%;
    }

    @include media(">=lgphone", "<tablet") {
      width: 100%;
    }

    @include media(">=tablet", "<desktop") {
      width: 100%;
    }
  }
}