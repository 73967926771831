.skills-container {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 20px;
    color: white;
    margin-bottom: 40px;
  }

  .skills-grid {
    // max-width: 700px;
    display: grid;
    // flex-wrap: wrap;
    grid-template-columns: repeat(6, 2fr);
    margin-top: 50px;
    grid-gap: 20px;

    @include media(">=phone", "<lgphone") {
      // grid-template-columns: 2fr;
      grid-template-columns: repeat(3, 2fr);
    }

    @include media(">=lgphone", "<tablet") {
      grid-template-columns: repeat(4, 2fr);
    }

    @include media(">=tablet", "<desktop") {
      grid-template-columns: repeat(5, 2fr);
    }

    h1 {
      color: white;
      font-size: 12px;
    }

    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      img {
        height: 3rem;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .skill-flex {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;


    .item {
      padding: 24px;
      // background-color: red;
      margin: 6px;
      display: flex;
      // width: 500px;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px;

        h1 {
          font-size: 12px;
        }
      }
    }

    .description {
      width: 200px;

      p {
        color: white;
        font-size: 12px;
        margin: 0;
      }

      p:nth-child(3) {
        color: white;
        opacity: 0.6;
        font-size: 11px;
      }

      p:nth-child(4) {
        color: white;
        opacity: 0.6;
        font-size: 11px;
      }
    }
  }

}

.ant-radio-wrapper {
  color: white;
}

.ant-progress-text {
  color: white;
}